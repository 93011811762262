import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { Injectable } from "@angular/core";
import { exhaustMap, take, tap } from "rxjs/operators";
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar";
import { JwtHelperService } from "@auth0/angular-jwt";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject, EMPTY, Observable } from "rxjs";
import { PageRequest, Page } from "ngx-pagination-data-source";
import { SpecificStatistics, Statistics } from "./statistics";
import { OrderList } from "app/orders/orders-list/order-list";

export interface OrderQuery {
  // status zamówienia
  statusCode?: string;
  // rok ostatniej aktualizacji zamówienia
  lastUpdatedOrderYear?: string;
  // tytuł zamówienia
  orderTitle?: string;
  // twórca
  creator?: string;
  // nazwa firmy
  companyName?: string;
  // nazwa linii
  conversionName?: string;
  // identyfikator linii
  conversionId?: string;
  // model busa
  busModel?: string;
  // waga pojazdu
  vehicleWeight?: string;
  // skrzynia biegów
  gearbox?: string;
  // światła
  lights?: string;
  // retarder telma
  retarderTelma?: string;
  // numer vin
  vinNumber?: string;
  // numer katalogowy
  catalogNumber?: string;
  // nazwa statusu
  statusName?: string;
  // typ zamówienia - oferta
  offer?: string;
  // zarchiwizowane zamówienia
  archivedAt?: string;
  // filtry związane z uprawnieniami:
  // możliwość wyświetlenia wszystkich własnych zamówień oraz tych nieutwardzonych
  viewOnlyOwnAndAllUnhardenedOrders?: string;
  // możliwość wyświetlenia tylko własnych zamówień
  viewOnlyOwnOrders?: string;
  // możliwośc wylaczenia limitu paginacji
  noLimit?: number;
}

const TOKEN_KEY = "id_token";

@Injectable({
  providedIn: "root",
})
export class OrderService {
  horizontalPosition: MatSnackBarHorizontalPosition = "center";
  verticalPosition: MatSnackBarVerticalPosition = "bottom";
  url = environment.url;
  show = true;
  showauto = false;
  autohide = true;

  // Private
  private readonly _order: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  private readonly _orders: BehaviorSubject<any[]> = new BehaviorSubject([
    null,
  ]);

  constructor(
    private http: HttpClient,
    private toast: MatSnackBar,
    private jwtHelper: JwtHelperService,
    private _translateService: TranslateService
  ) {}

  // getter zwróci ostatnią wyemitowaną wartość w _order BehaviorSubject
  get order$(): Observable<any> {
    return this._order.asObservable();
  }
  // setter
  set order(order: any) {
    // Store the value
    this._order.next(order);
  }

  get orders$(): Observable<any[]> {
    return this._orders.asObservable();
  }

  /**
   * Funkcja odpowiedzialna za pobranie listy zamówień
   * @since 04.08.2022 zmiana
   */
  page(request: PageRequest<any>, query: OrderQuery): Observable<Page<any>> {
    const pageIndex = request.page;
    const pageSize = request.size;
    const offset = pageIndex * pageSize;
    const sortOrder = request?.sort?.order == "asc" ? 1 : -1;
    const sortField = request?.sort?.property;
    const params: any = {
      ...query,
      sortField,
      sortOrder: sortOrder.toString(),
      pageIndex: pageIndex.toString(),
    };
    if (!query?.noLimit) {
      params["limit"] = pageSize.toString();
      params["offset"] = offset.toString();
    }
    if (query?.lights) {
      params["lights"] = JSON.stringify(query.lights);
    }

    return this.http
      .get<Page<any>>(`${this.url}/orders`, {
        params,
      })
      .pipe(
        tap((response) => {
          this._orders.next(response.content);
        })
      );
  }

  /**
   * Funkcja odpowiedzialna za utworzenie nowej numeracji dla specyfikacji
   * @since 03.08.2022
   */
  createOrderNumeration(statusCode: string, year: number) {
    return this.http.get<any>(
      `${this.url}/orders/count/numeration/?year=${year}&statusCode=${statusCode}`
    );
  }

  /**
   * Funkcja odpowiedzialna za pobranie szczegółów pojedynczego zamówienia na podstawie identyfikatora zamówienia
   * Dane dostępne przez getter order
   * @param id
   * @returns
   */
  getSingleOrder(id: string) {
    return this.http.get<any>(`${this.url}/orders/${id}`).pipe(
      tap((order: any) => {
        this._order.next(order);
      })
    );
  }

  /**
   * Funkcja odpowiedzialna za pobranie szczegółów pojedynczego zamówienia na podstawie numeru VIN
   * @since 08.12.2022
   * @param vinNumber
   * @returns
   */
  getSingleOrderByVinNumber(vinNumber: string) {
    return this.http.get<any>(`${this.url}/orders/vinNumber/${vinNumber}`).pipe(
      tap((order: any) => {
        this._order.next(order);
      })
    );
  }

  /**
   * Funkcja odpowiedzialna za wygenerowanie dokumentu pdf zamówienia
   * @param orderId
   * @param order
   * @param language
   * @returns
   */

  getOrderPdf(orderId: string, order: any, language: string) {
    return this.http.post<any>(
      `${this.url}/orders/generateOrderPdf/${orderId}?language=${language}`,
      { order },
      {
        responseType: "blob" as "json",
      }
    );
  }

  /**
   * Funkcja odpowiedzialna za wygenerowanie dokumentu kosztowego zamówienia
   * @param orderId
   * @returns
   */
  getOrderCostsPdf(orderId: string) {
    return this.http.post<any>(
      `${this.url}/orders/generateOrderCostsPdf/${orderId}`,
      {},
      {
        responseType: "blob" as "json",
      }
    );
  }

  /**
   * Funkcja odpowiedzialna za wygenerowanie dokumentu przedstawiającego rejestr zamówień
   * @param query
   * @returns
   */
  getOrderListPdf(query: OrderList) {
    return this.http.post<Page<any>>(
      `${this.url}/orders/generateOrderList`,
      { query },
      {
        responseType: "blob" as "json",
      }
    );
  }

  /**
   * Funkcja odpowiedzialna za aktualizację pojedynczego zamówienia
   * @param id identyfikator zamówienia
   * @param options przekazywane zmiany
   */
  updateOrder(id: string, options: {}) {
    return this.http.patch<any>(`${this.url}/orders/${id}`, options).pipe(
      tap((res) => {
        this.toast.open(
          this._translateService.instant("Pomyślnie wprowadzono zmiany."),
          this._translateService.instant("Zamknij"),
          {
            duration: 1000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
          }
        );
      })
    );
  }

  /**
   * Funkcja odpowiedzialna za archiwizację zamówienia
   * @since 05.01.2024
   * @param id
   * @returns
   */
  archiveOrder(id: string) {
    return this.http
      .post<any>(`${this.url}/orders/archive-order/${id}`, {})
      .pipe(
        tap((res) => {
          this.toast.open(
            this._translateService.instant("Pomyślnie wprowadzono zmiany."),
            this._translateService.instant("Zamknij"),
            {
              duration: 1000,
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
            }
          );
        })
      );
  }

  /**
   * Funkcja odpowiedzialna za utworzenie zamówienia
   * @param options
   * @param user
   * @param selectedUserId opcjonalny identyfikator twórcy zamówienia
   * @returns
   */
  createOrder(options: {}, selectedUserId?: string) {
    const token: any = localStorage.getItem(TOKEN_KEY);
    const loggedInUser = this.jwtHelper.decodeToken(token);
    return this.http
      .post<any>(`${this.url}/orders/`, {
        ...options,
        creator: selectedUserId ? selectedUserId : loggedInUser.userId,
      })
      .pipe(
        tap((res) => {
          this.toast.open(
            this._translateService.instant("Pomyślnie utworzono zamówienie."),
            this._translateService.instant("Zamknij"),
            {
              duration: 1000,
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
            }
          );
        })
      );
  }

  /**
   * Funkcja odpowiedzialna za usunięcie zamówienia
   * @param id
   * @returns
   */
  deleteOrder(id: string) {
    return this.http.delete<any>(`${this.url}/orders/${id}`).pipe(
      tap((res) => {
        this.toast.open(
          this._translateService.instant("Pomyślnie usunięto zamówienie."),
          this._translateService.instant("Zamknij"),
          {
            duration: 5000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
          }
        );
      })
    );
  }

  /**
   * Funkcja odpowiedzialna za sklonowanie obrazów
   * @param orderId
   * @param previousOrderId
   * @returns
   */
  cloneOrderImages(orderId: string, previousOrderId: string) {
    return this.http
      .patch<any>(`${this.url}/orders/${orderId}/images-clone`, {
        previousOrderId: previousOrderId,
      })
      .pipe(tap((res) => {}));
  }

  /**
   * Funkcja odpowiedzialna za dodanie obrazów do zamówienia
   * @param orderId
   * @param photos
   * @returns
   */
  uploadOrderImages(orderId: any, photos: any) {
    // Proces dodawania zdjęć
    if (photos) {
      return this.http
        .patch<any>(`${this.url}/orders/${orderId}/images`, photos, {
          reportProgress: true,
          observe: "events",
        })
        .pipe(
          tap((res: any) => {
            if (res.status == "200") {
              this.toast.open(
                this._translateService.instant("Pomyślnie dodano zdjęcia."),
                this._translateService.instant("Zamknij"),
                {
                  duration: 1000,
                  horizontalPosition: this.horizontalPosition,
                  verticalPosition: this.verticalPosition,
                }
              );
            }
          })
        );
    }
  }

  /**
   * Funkcja odpowiedzialna za pobranie statystyk dla zamówień
   * @param begin
   * @param end
   * @returns
   */
  getOrderStatistics(statistics: Statistics | SpecificStatistics) {
    const params: any = {
      ...statistics,
    };
    return this.http.get<any>(`${this.url}/orders/statistics`, { params });
  }

  /**
   * Funkcja odpowiedzialna za sprawdzenie czy dany numer VIN jest już utwardzony lub częściowo utwardzony
   * Jeśli jest już utwardzony lub częściowo utwardzony - zwróć false (brak możliwości utworzenia zamówienia)
   * @param vinNumber
   * @param orderId identyfikator wykluczonego zamówienia
   * @returns
   */
  checkVinNumber(vinNumber: string, orderId: string) {
    const params: any = {
      vinNumber,
      orderId,
    };
    return this.http.get<boolean>(
      `${this.url}/orders/vin-number/verification`,
      { params }
    );
  }

  /**
   * Funkcja odpowiedzialna za aktualizację wartości kosztów WDP dla określonego zamówienia
   * @since 08.07.2024
   * @param orderId
   * @param finances
   * @returns
   */
  updateOrderCosts(
    orderId: string,
    finances: {
      costs:
        | {
            productionReleases: any;
            warehouseCost: number;
          }
        | {
            baseVehicleCost: number;
          };
    }
  ) {
    return this.http
      .patch<any>(`${this.url}/orders/costs/update-order/${orderId}`, finances)
      .pipe(
        tap((res) => {
          this.toast.open(
            this._translateService.instant("Pomyślnie wprowadzono zmiany."),
            this._translateService.instant("Zamknij"),
            {
              duration: 1000,
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
            }
          );
        })
      );
  }

  /**
   * Funkcja odpowiedzialna za obliczenie kosztów magazynowych
   * @since 19.06.2024
   */
  updateProductionReleasesCosts(value: number, createdAt: Date) {
    const params: any = {
      value,
      createdAt,
    };
    return this.http.get<any>(
      `${this.url}/orders/production-releases/update-costs`,
      { params }
    );
  }

  /**
   * Funkcja odpowiedzialna za przeniesienie niektórych opcji z jednego zamówienia do drugiego
   * @since 09.02.2024
   * @param sourceOrderId identyfikator zamówienia z ktorego zapisywane będą dane
   * @param targetOrderId identyfikator zamówienia do którego nadpisane będą dane
   * @returns
   */
  updateOrderCopyIndividualItemsFromOneToAnother(
    sourceOrderId: string,
    targetOrderId: string
  ) {
    return this.http
      .patch<any>(
        `${this.url}/orders/update-order/copy-individual-items/${sourceOrderId}/${targetOrderId}`,
        {}
      )
      .pipe(
        tap((res: any) => {
          if (res) {
            this.toast.open(
              this._translateService.instant(
                "Pomyślnie zaimportowano specyfikację."
              ),
              this._translateService.instant("Zamknij"),
              {
                duration: 1000,
                horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,
              }
            );
          }
        })
      );
  }
}
